@use '../assets/Styles.scss' as styles;

@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.custom-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &-content {
    position: relative;
    background-color: styles.$quaternary-color;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    text-align: center;
    animation: zoomIn 0.3s ease-out; // Add this line to apply the zoom-in animation

    &-input {
      textarea {
        background-color: styles.$quaternary-color;
        border: 1px solid #ced4da;
        border-radius: 5px;
        resize: none;
        width: 100%;
      }
    }

    button {
      margin: 10px;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      background-color: styles.$secondary-color;
      color: #ffffff;
      cursor: pointer;
    }
  }

  &-close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 0.75em;
    cursor: pointer;
  }

  &-rtl {
    direction: rtl;
  }

  &-ltr {
    direction: ltr;
  }
}
