@use '../../assets/Styles.scss' as styles;

.dropdown div {
  cursor: pointer;
}

.empty_state {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  line-height: 26px;
  i {
    margin: auto;
    font-size: 90px;
    color: #ccc;
  }
  h3 {
    margin: 0;
    text-align: center;
    font-weight: normal;
  }
  p {
    margin: 0;
    font-size: 14px;
    color: #999;
    text-align: center;
  }
  button {
    margin: 5px auto;
  }
}
.profile {
  width: 55%;

  // center within .content
  margin: 25px auto 25px auto;
  background-color: styles.$quaternary-color;
  //padding: 5px 15px 15px 15px;
  transition: margin 0.4s, width 0.4s;

  display: flex;
  flex-direction: column;

  &-title {
    margin: 10px 0 0;
    width: 100%;
    text-align: center;
    font-weight: 400;
    text-decoration: none;

    &-name {
      border-bottom: 2px solid styles.$secondary-color-90;
    }
  }

  &-subtitle {
    margin: 5px 0 5px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    text-decoration: none;
    color: styles.$primary-color-50;
    font-size: 16px;
  }

  &-points {
    margin: 0 0 10px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    text-decoration: none;
    color: styles.$primary-color-75;
    font-size: 16px;
  }

  &-logout {
    justify-content: center;
    margin: 10px 5px 0;
  }

  h1 {
    margin: 0;
    padding: 0;
    width: 95%;
    font-size: 55px;
    font-weight: 100;
    text-transform: uppercase;  // capitalize
    transition: 0.4s;
  }

  &-primary {
    color: styles.$primary-color;

    &-secondary {
      color: styles.$secondary-color;
    }
  }

  &-secondary {
    color: styles.$secondary-color;

  }

  // Main styles
  .search {
    width: 70%;
    height: 40px;
    margin: 15px auto;
    background: transparent;
    border-radius: 3px;
    border: 1px solid styles.$primary-color;

    button {
      position: relative;
      float: right;
      border: 0;
      padding: 0;
      cursor: pointer;
      height: 40px;
      width: 120px;
      color: styles.$primary-color-90;
      background: transparent;
      border-left: 1px solid styles.$primary-color-90;
      border-radius: 0 3px 3px 0;
      &:hover {
        background: styles.$primary-color-90;
        color: styles.$tertiary-color;
      }
      &:active {
        box-shadow: 0 0 12px 0 rgba(225, 225, 225, 1);
      }
      &:focus {
        outline: 0;
      }
    }

    input {
      width: 370px;
      padding: 10px 5px;
      float: left;
      color: styles.$primary-color-90;
      border: 0;
      background: transparent;
      border-radius: 3px 0 0 3px;
      &:focus {
        outline: 0;
        background:transparent;
      }
    }
  }

  &-post {
    margin: 20px 10px 10px;

    hr {
      border: 0;
      height: 1px;
      background: styles.$primary-color-50;
      margin: 0 auto 5px;
    }

    &-language {
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      p {
        color: styles.$secondary-color;
        margin: 0;
      }

      &-left {
      }

      &-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        &-arrow {
          padding: 0;
          color: styles.$secondary-color;
          margin: 3px 0 0 0;
          font-size: 12px;
        }
      }

      &-header {

      }
    }

    &-date {
      color: styles.$primary-color-50;
      margin: 0;
      padding: 0 0 20px 0;
    }

    &-inputtext {
      background-color: styles.$quaternary-color;
      color: styles.$primary-color;
      width: 100%;
      resize: vertical;
      border: 1px solid styles.$primary-color-50;
      border-radius: 5px;
      transition: border 0.4s;
      font-size: 16px;

    }

    &-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      user-select: none;

      &-button {
        padding: 5px 10px;
        margin: 0 0 20px 0;
        font-size: 16px;
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid styles.$primary-color-90;
        background: transparent;
        transition: 0.4s;
        display: flex;
        align-items: center;
        gap: 5px;

        * {
          margin: 0;
          padding: 0;
          color: styles.$primary-color-90;
        }

        &:hover {
          background: styles.$primary-color-90;
          color: styles.$tertiary-color;
          * {
            color: styles.$tertiary-color;
          }
        }

        &:active {
          box-shadow: 0 0 12px 0 rgba(225, 225, 225, 1);
        }

        &:focus {
          outline: 0;
        }

        &-disabled {
          background-color: styles.$primary-color-40;
          cursor: not-allowed;
          &:hover {
            background-color: styles.$primary-color-40;
            color: styles.$primary-color-90;
            * {
              color: styles.$primary-color-90;
            }
          }
          &:focus {
            background-color: styles.$primary-color-40;
            color: styles.$primary-color-90;
            * {
              color: styles.$primary-color-90;
            }
          }
        }
      }
    }
  }

  &-delete {
    justify-content: center;
    margin: 5px 5px 10px;
  }

  /*
  &-card {
    margin: 20px 0 0 0;
    padding: 1px;
    border-radius: 10px;
    background: styles.$tertiary-color;
    overflow: hidden;
    width: 100%;

    &-inner {
      padding: 14px 15px;
      background-color: styles.$tertiary-color;
      border-radius: 10px;

      label {
        display: block;
        color: styles.$primary-color;
        margin: 0 0 15px 0;
        background: linear-gradient(45deg, styles.$primary-color, styles.$secondary-color-90);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        user-select: none;
      }

      &-search {
        display: flex;

        &-icon {
          min-width: 46px;
          min-height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          margin-right: 12px;
          box-shadow:
                  -2px -2px 8px styles.$tertiary-color,
                  2px 2px 8px styles.$primary-color-50;

          &:hover {
            box-shadow:
                    -2px -2px 10px styles.$tertiary-color,
                    2px 2px 6px styles.$primary-color-50;
            cursor: pointer;
          }
        }

        &-input {
          width: 100%;
          --top-shadow: inset 1px 1px 3px #c5d4e3, inset 2px 2px 6px #c5d4e3;
          --bottom-shadow: inset -2px -2px 4px rgba(255,255,255, .7);

          position: relative;
          border-radius: 10px;
          overflow: hidden;

          &:before,
          &:after {
            left: 0;
            top: 0;
            display: block;
            content: "";
            pointer-events: none;
            width: 100%;
            height: 100%;
            position: absolute;
          }

          &:before {
            box-shadow: var(--bottom-shadow);
          }

          &:after {
            box-shadow: var(--top-shadow);
          }

          input {
            background-color: #e3edf7;
            padding: 16px 25px;
            border: none;
            display: block;
            font-family: 'Orbitron', sans-serif;
            font-weight: 600;
            color: #a9b8c9;
            -webkit-appearance: none;
            transition: all 240ms ease-out;
            width: 100%;

            &::placeholder {
              color: #6d7f8f;
            }

            &:focus {
              outline: none;
              color: #6d7f8f;
              background-color: lighten(#e3edf7, 3%);
            }
          }
        }
      }
    }
  }
  */
}

@media (max-width: 1200px) {
  .profile {
    width: 100%;
    margin: 0 auto; // makes the animation smoother (centered)

    h1 {
      font-size: 40px;
    }
  }
}