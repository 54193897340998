@use '../assets/Styles.scss' as styles;

.content-box {
  background-color: styles.$quaternary-color;
  width: 100%;

  &-title {
    padding: 0 25px 0 25px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 34px;
    }

    a {
      text-decoration: none;
    }

    img {
      margin: 5px 0 0 0;
    }
  }

  &-description {
    padding: 0 25px 15px 25px;

    font-size: 16px;
  }

  &-first {
    background-color: styles.$primary-color-90;

    * {
      color: styles.$quaternary-color;
    }

    ::selection {
      background: styles.$quaternary-color;
      color: styles.$primary-color;
    }
  }

  &-ar {
    direction: rtl;
    font: 100% styles.$font-stack-arabic;

  }

  &-latin {
    direction: ltr;
    font: 100% styles.$font-stack;
  }

  &-buttons {
    display: flex;
    justify-content: space-around;
    padding: 10px;

    button {
      padding: 5px 10px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 5px;
      border-style: solid;
      border-width: 2px;
      color: styles.$primary-color;
      user-select: none;

      &:active {
        transform: scale(0.95);
      }

      &:hover {
        transition: background-color 0.5s, color 0.5s, border-color 0.5s;
      }
    }

    &-like-button {
      border-color: #4CAF50; /* Green */
      background-color: styles.$quaternary-color;
      color: white;

      &:hover, &:active {
        background-color: #4CAF50;
        border-color: #4CAF50;
      }

      &.clicked {
        border-color: #2b622d;
        background-color: #4CAF50;
      }
    }

    &-dislike-button {
      border-color: #f44336; /* Red */
      background-color: styles.$quaternary-color;
      color: white;

      &:hover, &:active {
        background-color: #f44336;
        border-color: #f44336;
      }

      &.clicked {
        border-color: #9b2b22;
        background-color: #f44336;
      }
    }

    &-report-button {
      padding: 5px 10px;
      font-size: 16px;
      cursor: pointer;
      //border: none;
      border-radius: 5px;
      //background-color: styles.$secondary-color;
      color: white;

      border-color: styles.$secondary-color;
      background-color: styles.$quaternary-color;

      &:active {
        transform: scale(0.95);
      }

      &:hover, &:active {
        background-color: styles.$secondary-color;
        border-color: styles.$secondary-color;
      }

      &.clicked {
        border-color: black;
        background-color: #101010;
        color: white;

        cursor: not-allowed;

        &:active {
          transform: none;
        }
      }
    }

    &-share-button {
      padding: 5px 10px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 5px;

      border-color: styles.$primary-color-75;
      background-color: styles.$quaternary-color;
      color: white;

      &:active {
        transform: scale(0.95);
      }

      &:hover, &:active {
        background-color: styles.$primary-color;
        border-color: styles.$primary-color;
        color: styles.$quaternary-color;
      }
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: calc(100% - 9px); /* Position it above the button */
  left: 50%;
  transform: translateX(-50%); /* Center the tooltip */
  opacity: 0; /* Start fully transparent */
  transition: opacity 0.3s, transform 0.3s; /* Animate opacity and position */
}

.tooltip .tooltiptext button {
  background: none;
  border: none;
  color: #fff;
  padding: 5px;
  cursor: pointer;
}

.tooltip .tooltiptext button:hover {
  text-decoration: underline;
}

.tooltip:hover .tooltiptext,
.tooltip .tooltiptext:hover {
  visibility: visible;
  opacity: 1; /* Fade in */
  transform: translateX(-50%) translateY(-10px); /* Move up slightly */
}