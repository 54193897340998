@use '../../assets/Styles.scss' as styles;

.word-of-day {
  padding: 25px;
  margin: 0;
  overflow: auto;
  text-align: justify;

  &-word {
    padding: 0;
    margin: 10px 0 0 80px;
    height: 400px;
    overflow: visible;
    font: 100% styles.$font-stack-arabic-wod;
    font-size: 250px;
    font-weight: 400;
    font-style: normal;
    color: styles.$secondary-color;
    //background: linear-gradient(to right, app.$secondary-color 0%, app.$primary-color 100%);
    //-webkit-background-clip: text;
    //-webkit-text-fill-color: transparent;
    display: flex;
    flex-direction: column-reverse;
    float: right;

    @media (max-width: 1200px) {
      width: 100%;
      font-size: 22.5vw;
      margin: -20% 0 0 0;
      height: auto;
    }
  }

  &-definition {
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: auto;

    display: inline;

    &-ar {
      padding: 0 0 15px 0;
      margin: 0;
      font: 100% styles.$font-stack-arabic;
      color: styles.$primary-color;
      font-size: 2.5rem;
      line-height: 1.4;
      font-weight: 400;
      font-style: normal;
      direction: rtl;

      @media (max-width: 1100px) {
        font-size: 2rem;
      }
    }

    &-en {
      padding: 0;
      margin: 0;
      font: 100% styles.$font-stack;
      color: styles.$primary-color;
      font-size: 2.5rem;
      line-height: 1.3;
      font-weight: 400;
      font-style: normal;
      direction: ltr;

      @media (max-width: 1100px) {
        font-size: 2rem;
      }
    }
  }
}

