@use '../../assets/Styles.scss' as styles;

.container-input-center {
  align-items: center; /* Center horizontally */
}

.feed {
  width: 100%;
}

.zero-side-padding {
  padding-left: 0;
  padding-right: 0;
}