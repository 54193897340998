@use '../assets/Styles.scss' as styles;
$header-shadow: rgba(0, 0, 0, 0.1);

.react-joyride__tooltip {
    white-space: pre-line;
}

/* The Overlay (background) */
.nav-overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    right: 0;
    top: 0;
    background-color: styles.$tertiary-color-90;
    overflow-x: hidden;
    transition: 0.5s ease;
    font-family: styles.$font-stack;
    font-weight: 500;

    &-content {
        position: relative;
        top: 25%;
        width: 100%;
        text-align: center;
        margin-top: 30px;

        a {
            padding: 8px;
            text-decoration: none;
            font-size: 36px;
            color: styles.$primary-color;
            display: block;
            transition: 0.3s;
            opacity: 0.6;

            &:hover, &:focus {
                color: #f1f1f1;
            }

            &.active {
                color: styles.$secondary-color;
                opacity: 0.85;
            }
        }

        &-bottom {
            width: 100%;
            position: fixed;
            padding: 0 0 20px 0;
            bottom: 35px;
            opacity: 0.85;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            &-mode {
                margin: 0;
                padding: 15px 0 0 0;
            }

            &-language {
                width: 50px;
                margin: 0;
                padding: 8px 0 0 0;
            }

            &-user {
                margin: 0;
                padding: 0;

                a {
                    padding: 0;
                }
            }
        }
    }
}

.header {
    /*background-color: #D5D5D5; /* Light gray background color */
    background-color: styles.$quaternary-color; /* Dark gray background color */
    height: 90px;
    width: 100%;
    flex-shrink: 0; /* Prevent the header from shrinking when content grows */
    display: flex;
    flex-direction: row; /* Stack toolbar items and search bar vertically */
    align-items: center; /* Center the content horizontally */
    justify-content: space-between;

    z-index: 100;
    //#eae7e7
    //#1e1e1e
    border-bottom: 1px solid styles.$header-border-color;
    box-shadow: 0 -6px 5px 5px $header-shadow;
    transition: height 0.5s ease-in-out;

    &-left-side {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 0 0 15px;

        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;

        img {
            height: clamp(10px, 10vw, 50px);
            margin: 0 35px 0 0;

            // undraggable
            -webkit-user-drag: none;
        }
    }

    &-right-side {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 1rem 0 0;
        gap: 16px;

        a {
            color: styles.$secondary-color;
            font-size: 17px;
            opacity: 1;

            &:hover {
                opacity: 0.5;
            }

            &.active {
                color: styles.$primary-color;
                opacity: 0.5;
            }
        }

        .SearchBar {
            padding: 0;
            margin: 0;
        }

        &-add {
            margin: 0;
            -webkit-transition: padding-right 0.4s ease-in-out;
            transition: padding-right 0.4s ease-in-out;
            -webkit-transition-delay: 0.4s;
            transition-delay: 0.4s;

            padding: 8px;
            background-color: styles.$secondary-color-20;
            border-radius: 35%;
        }

        &-divider {
            width: 2.5px;
            height: 50px;
            background-color: styles.$icon-background-color;
        }

        &-language {
            padding: 0;
            margin: 0;
            height: 25px;
            position: relative;
            z-index: 1000;

            display: flex;
            flex-direction: row;

            p {
                color: styles.$primary-color;
                font: 100% styles.$font-stack;
                padding: 0 0 0 5px;
                align-self: center;
                user-select: none;

                // This is a cool effect, it makes the text's background an image
                // but it doesn't look good with the current background
                //display: inline-block;
                //margin: 0;
                //font-size: 30px;
                //background-image: url(
                //'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/English_language.svg/800px-English_language.svg.png?20141018184419');
                //font-weight: bold;
                //background-repeat: no-repeat;
                //background-size: cover;
                //background-position: center;
                //color: transparent;
                //background-clip: text;
                //-webkit-background-clip: text;
                //-webkit-text-stroke-width: 0.1px;
                //-webkit-text-stroke-color: white;
            }

            cursor: pointer;
        }

        &-mode {
            padding: 0;
            margin: 0;
            height: 25px;
            //display: flex;
        }

        &-user {
            margin: 0;
            height: 25px;

            padding: 10px;
            background-color: styles.$icon-background-color;
            border-radius: 35%;

            img {
                width: 100%;
                height: 100%;

                cursor: pointer;
                -webkit-user-drag: none;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;

                &:hover {
                    opacity: 50%;
                }
            }
        }

        /* Burger menu */
        &-burger {
            display: inline-block;
            cursor: pointer;
            margin: 0;

            .bar1, .bar2, .bar3 {
                width: 35px;
                height: 5px;
                background-color: styles.$secondary-color;
                margin: 6px 0;
                transition: 0.4s;
            }

            &.change {
                .bar1 {
                    transform: translateY(11px) rotate(-45deg);
                }

                .bar2 {
                    opacity: 0;
                }

                .bar3 {
                    transform: translateY(-11px) rotate(45deg);
                }
            }
        }
    }
}

/* Hide the burger menu on screens wider than 1200px */
@media (min-width: 1201px) {
    .header-right-side-burger {
        display: none;
    }
}

/* Hide existing menu on screens narrower than 1201px */
@media (max-width: 1200px) {
    .toolbar-items {
        display: none;
    }

    .header {
        height: 65px;

        &-right-side{
            &-mode {
                display: none;
            }

            &-language {
                display: none;
            }

            &-user {
                display: none;
            }
        }
    }

    .search-box {
        margin: 0 15px 0 0;
    }

    @media (max-height: 600px) {
        .nav-overlay-content-bottom {
            position: static;
        }
    }
}