@use '../../assets/Styles.scss' as styles;

.blog {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  h1 {
    text-align: center;
    margin-bottom: 2rem;
    color: styles.$primary-color;
  }

  // Form styles
  form {
    background: styles.$quaternary-color;
    padding: 1.5rem;
    border-radius: 12px;
    margin-bottom: 3rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    input, textarea {
      width: calc(100% - 1.6rem); // Accounting for padding
      padding: 0.8rem;
      margin-bottom: 1rem;
      border: 1px solid rgba(styles.$primary-color, 0.2);
      border-radius: 6px;
      background-color: styles.$tertiary-color;
      color: styles.$primary-color;

      &::placeholder {
        color: rgba(styles.$primary-color, 0.6);
      }
    }

    textarea {
      resize: vertical; // Only allow horizontal resizing
      // default 4 lines
      min-height: 4rem;
    }

    button {
      padding: 10px;
      background-color: styles.$secondary-color;
      color: styles.$primary-color-dark;
      border: none;
      cursor: pointer;
      width: 100%;
      border-radius: 6px;

      &:disabled {
        background-color: styles.$primary-color-40;
        cursor: not-allowed;
      }

      &.preview-button {
        &.disabled {
          background-color: styles.$primary-color-40;
          cursor: not-allowed;
        }
      }
    }
  }

  // Posts container
  .posts {
    display: grid;
    gap: 2rem;

    .post {
      background: styles.$quaternary-color;
      border-radius: 12px;
      padding: 2rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      // Post header
      .post-header {
        margin-bottom: 1.5rem;

        h2 {
          color: styles.$primary-color;
          margin: 0;
          font-size: 1.5rem;
        }

        .post-date {
          color: styles.$secondary-color;
          font-size: 0.9rem;
          font-weight: bold;
          margin-top: 0.5rem;
          display: block;
        }
      }

      // Post content
      .post-content {
        color: styles.$primary-color;

        h1, h2, h3, h4, h5, h6 {
          color: styles.$primary-color;
          margin: 1.5rem 0 1rem;
        }

        p {
          line-height: 1.6;
          margin: 1rem 0;
        }

        // Simple HR styling
        hr {
          border: none;
          height: 2px;
          background: linear-gradient(
                          to right,
                          styles.$primary-color-40,
                          styles.$primary-color-50,
                          styles.$primary-color-40
          );
          margin: 1.5rem 0;
        }


        a {
          color: styles.$secondary-color;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        blockquote {
          border-left: 4px solid styles.$secondary-color;
          margin: 1rem 0;
          padding-left: 1rem;
          color: rgba(styles.$primary-color, 0.8);
        }
      }
    }
  }

  // Style for the login message
  .login-message {
    text-align: center;
    font-size: 1rem;
    color: styles.$primary-color;
    margin: 0 0 1.5rem 0;
    border: 1px solid styles.$primary-color-40;
    border-radius: 6px;
  }

  .login-link {
    color: styles.$secondary-color;
    text-decoration: none;
    position: relative;
    transition: color 0.3s;
    font-style: normal;  // Override italic from parent
    opacity: 1;  // Override opacity from parent

    &:hover {
      color: styles.$secondary-color-75;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -2px;
      left: 0;
      background-color: styles.$secondary-color;
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out;
    }

    &:hover::after {
      visibility: visible;
      transform: scaleX(1);
    }
  }
}

.preview-container {
  background: var(--tertiary-color);
  border: 1px solid rgba(var(--primary-color), 0.2);
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 150px;

  h2 {
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
}
.form-buttons {
  display: flex;
  gap: 1rem;

  button {
    flex: 1;

    &.preview-button {
      background-color: var(--quaternary-color);

      &:hover {
        background-color: var(--tertiary-color);
      }
    }
  }
}



// Dark mode specific overrides
[data-theme='dark'] {
  .blog form {
    input::placeholder,
    textarea::placeholder,
    textarea::-webkit-input-placeholder,
    textarea:-moz-placeholder,
    textarea::-moz-placeholder,
    textarea:-ms-input-placeholder {
      color: rgba(styles.$primary-color, 0.6) !important;
    }
  }
}


// Light mode specific overrides
[data-theme='light'] {
  .blog form {
    input::placeholder,
    textarea::placeholder,
    textarea::-webkit-input-placeholder,
    textarea:-moz-placeholder,
    textarea::-moz-placeholder,
    textarea:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
}
