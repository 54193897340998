@use "../../assets/Styles.scss" as styles;

.page-under-construction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
    text-align: center;
    color: styles.$primary-color;

    h1 {
        font-size: 43px;
        margin-bottom: 10px;
    }

    p {
        font-size: 24px;

        mark {
            background-color: styles.$secondary-color-90;
            border-radius: 5px;
            font-weight: bold;
        }
    }
}