@use './assets/Styles.scss' as styles;

::selection {
  background: styles.$primary-color;
  color: styles.$tertiary-color;
}

* {
  scrollbar-width: none;
}
/* custom scrollbar */
//::-webkit-scrollbar {
//  width: 18px;
//}
//
//::-webkit-scrollbar-track {
//  background-color: transparent;
//}
//
//::-webkit-scrollbar-thumb {
//  background-color: styles.$primary-color-75;
//  border-radius: 20px;
//  border: 6px solid transparent;
//  background-clip: content-box;
//}
//
//::-webkit-scrollbar-thumb:hover {
//  background-color: styles.$primary-color;
//}

body {
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Set the height of the app to 100% of the viewport height */
  background-color: styles.$tertiary-color;
}

.toolbar-items {
  margin-bottom: 7px; /* Add some spacing between toolbar items and the search bar */
}

.search-bar {
  /* Add styles for the SearchBar component if necessary */
}

.content {
  overflow: hidden;
  flex: 1;
}

.report {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: styles.$primary-color-75;
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 1000;
  cursor: pointer;
  color: styles.$tertiary-color;
  display: flex;
  justify-content: center;
  align-items: center;

  &-icon {
    opacity: 90%;
  }

  &:hover {
    background-color: styles.$primary-color;
  }
}